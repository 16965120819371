<template>
  <div>
    kns2021
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: 'Home',
  created () {
    if (localStorage.getItem('user_code')) {
      this.$router.push({ name: 'Welcome' })
    } else {
      this.$router.push({ name: 'Login' })
    }
  }
}
</script>
